import JavDropdown from "../JavDropdown";
import { CaretDown, NotificationIcon } from "../JavIcons"; // Add MenuIcon
import React, {useEffect, useState} from "react"; // Add useState
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { switchAccount, userLogout } from "../../../Modules/Login/duck/action";
import Styles from "../Menu/Menu.module.css";
import LangDropDown from "../LangDropDown";
import NotificationBadge from "../Notification/NotificationBadge";
import ApplicationNavigationBar from "./ApplicationNavigationBar";
import MenuIcon from "../Icons/MenuIcon";
import styles from "../Menu/Menu.module.css";

export default function ApplicationToolbar(props) {
  // const [isSidebarVisible, setIsSidebarVisible] = useState(false); // State for sidebar visibility
  const userDetails = useSelector((state) => state?.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const items = userDetails?.user_companies?.map((item) => {
    return {
      label: `${item["business"]["full_name"]} - ${item["business"]["business_name"]}`,
      key: "1",
      onClick: () => {
        dispatch(
          switchAccount(
            {
              user_company_id: item.id,
            },
            history
          )
        );
      },
    };
  });

  useEffect(() => {
      console.log("userDetails",userDetails);
  },[userDetails])

  return (
    <div
      className={
        "tw-h-16 tw-px-2  tw-flex tw-items-center tw-justify-between lg:tw-justify-end tw-shadow-sm tw-z-10 tw-sticky tw-top-0 tw-left-0 tw-right-0 tw-border-b tw-bg-white"
      }
    >
      <div>
        <button
            className="tw-mr-3 lg:tw-hidden tw-p-2 tw-border tw-rounded-full"
            onClick={() => {
              props.setIsSidebarVisible(!props.isSidebarVisible)}
          }
        >
          <MenuIcon width="1.2em" height="1.2em" className="javIcon"/>
        </button>
      </div>

      <div className={"tw-flex tw-items-center"}>

          {
              items?.length >  0 &&
              <div style={{ display: "flex", alignItems: "center" }} >
                  <Dropdown
                      menu={{
                          items,
                      }}
                      trigger={['click']}
                  >
                      <div className={styles.userName}>
                          Switch Account
                          <div style={{marginLeft: "0.5em"}}>
                              <CaretDown width={"1em"} color="#727986" />
                          </div>
                      </div>
                  </Dropdown>

              </div>
          }
        <div className={Styles.langMenu}>
          <LangDropDown/>
        </div>

        <div
            className={
              "tw-mr-3 tw-p-2.5 tw-border tw-rounded-full tw-cursor-pointer"
            }
            onClick={() => {
              history.push("/business/notification");
            }}
        >
          <NotificationIcon width="1.2em" height="1.2em" className="javIcon"/>
          <NotificationBadge count={3}/>
        </div>

        <JavDropdown
            topSpace={9}
            actions={[
              {
                title: "Profile",
                onClick: () => {
                  history.push("/business/profile");
                  props.setIsSidebarVisible(false)
                },
              },
              {
                title: "Logout Session",
                onClick: () => {
                  dispatch(userLogout(history, "business"));
                  props.setIsSidebarVisible(false)
                },
              },
            ]}
        >
          <div
              className={
                "tw-flex tw-items-center tw-justify-center tw-p-2 tw-border tw-rounded-full"
              }
          >
            <div>
              <Avatar src={userDetails?.image_url} icon={<UserOutlined/>}/>
            </div>

            <span className={"tw-text-center tw-flex tw-mx-2 tw-text-nowrap"}>
            {userDetails?.type === "business"
                ? userDetails?.full_name
                : userDetails?.first_name}
          </span>
            <div style={{marginLeft: "0.5em"}}>
              <CaretDown width={"1em"} color="#727986"/>
            </div>
          </div>
        </JavDropdown>
      </div>


    </div>
  );
}
