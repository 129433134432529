
export const lang = {
    EN: {
        HOME: 'Home',
        DASHBOARD: 'Dashboard',
        PROGRESS: 'Progress',
        MAPVIEW:'Map View',
        APPLICATIONS: 'Applications',
        PRODUCTS: 'Products',
        JAVOLIN_WALLET: 'Javolin Wallet',
        TOTAL_BALANCE: 'Total Balance',
        MAKE_DEPOSIT: 'Make Deposit',
        GET_STARTED: 'Get Started',
        SAVINGS: 'Savings',
        JAVOLIN_LOANS: 'Javolin Loans',
        JAVOLIN_LOAN_INSTRUCTION: "Javolin Loan Instruction",
        JAVOLIN_LOAN_BENEFICIARY_SELECTION_PLACEHOLDER: "Select beneficiary bank account",
        JAVOLIN_LOANS_DESC: 'Apply for a mortgage and other loan products in minutes',
        JAVOLIN_LOANS_ACTION: 'Apply for a loan now',
        BUY_AIRTIME: 'Buy Airtime',
        BUY_AIRTIME_DESC: 'Top up airtime account',
        BUY_AIRTIME_ACTION: 'Send airtime now',
        SEND_MONEY: 'Send money',
        NEW: 'new!',
        COMING_SOON: 'coming soon!',
        SECURE_CREDIT_CARD: 'Secure credit card',
        LINE_GRAPH_DESC: 'Line Graph of transaction volumes by type',
        RECENT_TRANSACTION: 'Transactions',
        REFERENCE: 'Reference',
        INVEST: "Invest - Crypto",
        AMOUNT: 'Amount',
        TRANSACTION_TYPE: 'Transaction Type',
        DATE: 'Date',
        EDIT_PROFILE: 'Edit Profile',
        LOGOUT: 'Logout',
        HI: 'Hi',
        VIEW_ALL: 'View all',
        SERVICES: "Services",
        FUND_MY_WALLET: "Fund My wallet",
        LOANS: "Javolin Loans",
        FUND_MY_WALLET_MSG: "Top up your Javolin account",
        SEND_MONEY_MSG: "Send money to family and friends",
        TRANSFER_MONEY: "Transfer Money",
        TRANSFER_MONEY_MSG: "Move money between your accounts",
        PAYMENTS: "PAYMENTS",
        GOV_FEE: "Government Fees",
        APPLY_NOW: "apply now",
        LOAN: "LOAN",
        LOAN_DESC: "Loans",
        CARD: "CARD",
        CARD_DESC: "Secured Credit /Debit card",
        PORTFOLIO: "Porfolio",
        WALLET: "Wallet",
        PROFILE: "Profile",
        ACCOUNT_BALANCE: "Account balance",
        ACCOUNT_NUMBER: "Account number",
        WELCOME_MSG: "Welcome to Javolin! Get to know more about us. Watch this 60 seconds video",
        HELLO_THERE: "Hello to Javolin",
        FIRST_NAME: "First name",
        SURNAME: "Last Name",
        PHONE: "Phone number",
        PASSWORD: "Password",
        CONFIRM_PASSWORD: "Confirm Password",
        EMAIL: "Email",
        ADDRESS: "Physical Address",
        DOB: "Date of Birth",
        PRIVACY_AGREEMENT: "I agree with your Terms of Service and Privacy Notice",
        VERIFY_IDENTITY: "Verify your identity",
        VERIFY_IDENTITY_MSG: "Click here to set up your KYC to secure your account",
        EXCHANGE_RATE: "EXCHANGE RATES",
        MORTGAGE: "Mortage",
        MORTGAGE_WELCOME_MSG: "Mortgages are complicated. We make that our problem, not yours. Buy a home now.",
        ADD_CARD: "Add new card",
        MY_CARDS: "My Wallets",
        STATUS: "Status",
        GET_PREPAID: "Get Prepaid Cards",
        GET_PREPAID_MSG: "Get your physical or virtual card and make fast and seemless transactions",
        FUNDING_SOURCES: "Funding Sources",
        ADD_NEW_FUNDING: "Add new funding source",
        APPLICATION_STATUS: "Application Status",
        PREPAID: "Prepaid",
        PENDING: "Pending",
        VIEW: "View",
        PERSONAL_INFO: "Personal Information",
        CHANGE_PASSWORD: "Change Password",
        CHANGE_PIN: "Change Pin",
        GET_HELP: "Get Help",
        CONTACT: "Contact",
        QUESTION: "Question",
        ANSWER: "Answer",
        PASS_CHANGE_MSG: "You will need to answer this question to reset your password",
        CHANGE_PIN_MSG: "You will need to enter your current pin and a new pin",
        CURRENT_PIN: "Current Pin",
        NEW_PIN: "New Pin",
        SUBMIT: "Submit",
        CONTACT_INPUT_PLACEHOLDER: "Tell us how we can help",
        UPDATE: "Update",
        CHANGE_PROFILE_PIC: "Change Profile Picture",
        NEW_PASSWORD: "New Password",
        OLD_PASSWORD: "Old Password",
        SECURITY_QUESTIONS_INTRO: "You will need to answer this question to reset your password",
        CONTINUE: "Continue",
        FUNDING_SOURCE: "FUNDING SOURCE",
        FUND_FROM: "Fund from",
        FUNDING_DETAILS: "FUNDING DETAILS",
        FUNDING_DESTINATION: "Funding Destination",
        NOTE: "Note",
        OPTIONAL: "optional",
        FUND_WALLET: "Fund Wallet",
        DESTINATION: "destination",
        SELECT_ACCOUNT: "Select Account",
        NOTE_INPUT_PLACEHOLDER: "Eg. Stipend",
        SOURCE: "Source",
        SEND_FROM: "Send From",
        SEND_TO: "Send to",
        RECEPIENT: "Recepient",
        ACCOUNT_NAME: "Account Name",
        SELECT_NETWORK: "Select Network",
        BACK_TO_HOME: "Back to home",
        REVIEW: "Review",
        SENDING_FROM: "SENDING FROM",
        SENDING_TO: "SENDING TO",
        TRANSFER_FEE: "TRANSFER FEE",
        TOTAL_PAYABLE: "TOTAL PAYABLE",
        FOREIGN_EXCHANGE: "Foreign exchange",
        FOREIGN_EXCHANGE_DESC: "Get foreign exchange services.",
        PAYMENT_SENDMONEY: "Payments / Send Money",
        WELCOME_TO: "Welcome to",
        WELCOME_SUB: "Financial services you can trust.",
        "Sign in": "Sign in",
        "Sign in to start trading with us": "Sign in to start trading with us",
        "Forgot Password?": "Forgot Password?",
        "Phone number": "Phone number",
        "Phone Number": "Phone Number",
        "Password?": "Password",
        "Please input your password!": "Please input your password!",
        "Don’t have an account?": "Don’t have an account?",
        "Create account": "Create account",
        "Phone number is required": "Phone number is required",
        "Login": "Login",
        "English": "English",
        "French": "French",
        "Register": "Register",
        "Create an account and start trading with us": "Create an account and start trading with us",
        "Name of Business": "Name of Business",
        "is required": "is required",
        "Type of business": "Type of business",
        "Enter business name": "Enter business name",
        "Enter business type": "Enter business type",
        "Email": "Email",
        "Physical Address": "Physical Address",
        "Continue": "Continue",
        "Already have an account?": "Already have an account?",
        "Pending Requests": "Pending Requests",
        "User Management": "User Management",
        "Users": "Users",
        "Roles": "Roles",
        "Home": "Home",
        "Overview in 60s": "Overview in 60s",
        "Secure your Account": "Secure your Account",
        "Set your account pin": "Set your account pin",
        "Business KYC": "Business KYC",
        "Provide your company details": "Provide your company details",
        "Let's secure your account": "Let's secure your account",
        "Javolin Services": "Javolin Services",
        "Completed": "Completed",
        "Balance": "Balance",
        "Transactions": "Transactions",
        "Your Wallet": "Your Wallet",
        "Add account": "Add account",
        "Rate result appears here": "Rate result appears here",
        "Foreign Exchange Spot Rate": "Foreign Exchange Spot Rate",
        "Buy": "Buy",
        "Sell": "Sell",
        "Amount": "Amount",
        "Wallet": "Wallet",
        "Active accounts": "Active accounts",
        "No data": "No data",
        "Transaction ID": "Transaction ID",
        "Action": "Action",
        "view": "view",
        "decline": "decline",
        "Review Pending Transaction": "Review Pending Transaction",
        "Transaction made by": "Transaction made by",
        "User name": "User name",
        "Beneficiary account": "Beneficiary account",
        "Bank name": "Bank name",
        "Source account": "Source account",
        "Initial Trans. Amount": "Initial Trans. Amount",
        "Transaction fee": "Transaction fee",
        "Trans. fee": "Trans. fee",
        "Selling Amount": "Selling Amount",
        "approve": "approve",
        "cancel": "cancel",
        "edit": "edit",
        "delete": "delete",
        "Full name": "Full name",
        "Full Name": "Full Name",
        "Add new user": "Add new user",
        "Add user": "Add user",
        "User List": "User List",
        "Role List": "Role List",
        "Role Name": "Role Name",
        "Role Permissions": "Role Permissions",
        "Set Transaction Limit": "Set Transaction Limit",
        "Add Role": "Add Role",
        "Add new role": "Add new role",
        "Manage your wallet": "Manage your wallet",
        "Currency": "Currency",
        "Select the default currency for your account": "Select the default currency for your account",
        "check rate": "check rate",
        "book rate": "book rate",
        "Your deal has been booked. Your deal number is": "Your deal has been booked. Your deal number is",
        "Deal Booked": "Deal Booked",
        "This rate expires in": "This rate expires in",
        "Instruct this deal": "Instruct this deal",
        "Beneficiary details": "Beneficiary details",
        "Send to": "Send to",
        "Transaction Type": "Transaction Type",
        "Sending info": "Sending info",
        "Sending From": "Sending From",
        "Beneficiary Receives": "Beneficiary Receives",
        "Add New Beneficiary": "Add New Beneficiary",
        "Destination": "Destination",
        "Send Money": "Send Money",
        "Other Javolin Account": "Other Javolin Account",
        "Mobile Money (Momo) Account": "Mobile Money (Momo) Account",
        "Prepaid Visa Card": "Prepaid Visa Card",
        "Bank Account": "Bank Account",
        "Select transaction type": "Select transaction type",
        "Domestic": "Domestic",
        "International": "International",
        "Note": "Note",
        "Optional": "Optional",
        "Transfer Money": "Transfer Money",
        "Transfer From": "Transfer From",
        "Transfer To": "transfert à",
        "Select Bank": "Select Bank",
        "Phone number is invalid": "Phone number is invalid",
        "The two passwords that you entered do not match!": "The two passwords that you entered do not match!",
        "re-enter password": "re-enter password",
        "Should accept agreement": "Should accept agreement",
        "Privacy Policy": "Privacy Policy",
        "I agree with your": "I agree with your",
        "Terms of Service": "Terms of Service",
        "and": "and",
        "Confirm Password": "Confirm Password",
        "Sign up": "Sign up",
        "Password": "Password",
        "Loans": "Loans",
        "Business Account Application": "Business Account Application",
        "seconds": "secondes",
        "Rate has expired": "Rate has expired",
        "ADD NEW ACCOUNT": "ADD NEW ACCOUNT",
        "Description": "Description",
        "Account Name": "Account Name",
        "Add Account": "Add Account",
        "Forex": "Forex",
        "You are seeking to buy": "You are seeking to buy",
        "hence the beneficiary account you select or add should be a": "hence the beneficiary account you select or add should be a",
        "You are seeking to sell": "You are seeking to sell",
        "hence the your source account should be a": "hence the your source account should be a",
        "Select bank account with buying currency": "Select bank account with buying currency",
        "Beneficiary Details": "Beneficiary Details",
        "Select Loan Type": "Select Loan Type",
        "Loan Details": "Loan Details",
        "Beneficiary": "Beneficiary",
        "currency": "currency",
        "Rate": "Rate",
        "You haven’t added any users yet. Add new": "You haven’t added any users yet. Add new",
        "Role": "Role",
        "You haven’t added any roles yet. Add new role": "You haven’t added any roles yet. Add new role",
        'Select role': 'Select role',
        "You don't have any pending transactions": "You don't have any pending transactions",
        "Module": "Module",
        "Date": "Date",
        "User": "User",
        "Account Number": "Account Number",
        "Dashboard": "Dashboard",
        "Input invalid": "Input invalid",
        "optional": "optionnel",
        "Bank": "Bank",
        "Source": "Source",
        "Send From": "Send From",
        "We have sent a 6-digit code to your phone and email": "We have sent a 6-digit code to your phone and email",
        "Save": "Save",
        "Submit": "Submit",
        "account": "account",
        "Edit user": "Edit user",
        "Status": "Status",
        "Edit Role":"Edit Role",
        "Reason": "Reason",
        "Corporate Treasury":"Corporate Treasury",
        "In-Market Treasury Clients":"In-Market Treasury Clients",
        "Other":"Other"
    },
    FR: {
        "In-Market Treasury Clients":"In-Market Treasury Clients",
        "Corporate Treasury":"Corporate Treasury",
        "Reason": "Reason",
        "Other":"Other",
        "Edit Role": "Modifier le rôle",
        "Status": "Le Statut",
        "Edit user": "Modifier l'utilisateur",
        "Provide your company details": "Fournir les renseignements sur votre entreprise",
        "account": "compte",
        "We have sent a 6-digit code to your phone and email": "Nous avons envoyé un code à six chiffres à votre téléphone et à votre courriel",
        "Send From": "Envoyer Depuis",
        "Source": "Source",
        "Bank": "Banque",
        "Select Bank": "Sélectionner la banque",
        "optional": "optionnel",
        "Input invalid": "Non valide d'entrée",
        "Dashboard": "Tableau de bord",
        "Account Number": "Numéro de compte",
        "Module": "Module",
        "Date": "Date",
        "User": "Utilisateur",
        "You don't have any pending transactions": "Vous n’avez pas de transactions en attente",
        'Select role': 'Sélectionner un rôle',
        "You haven’t added any roles yet. Add new role": "Vous n’avez pas encore ajouté de rôle. Ajoutez un nouveau rôle",
        "You haven’t added any users yet. Add new": "Vous n’avez pas encore ajouté d’utilisateurs. Ajouter de nouveaux",
        "Rate": "Taux de change",
        "Beneficiary": "Bénéficiaire",
        "Beneficiary Details": "Détails du bénéficiaire",
        "Select bank account with buying currency": "Sélectionnez le compte bancaire avec la devise d’achat",
        "hence the your source account should be a": "donc votre compte source devrait être un",
        "You are seeking to sell": "Vous cherchez à vendre",
        "hence the beneficiary account you select or add should be a": "par conséquent, le compte bénéficiaire que vous sélectionnez ou ajoutez doit être un",
        "You are seeking to buy": "Vous cherchez à acheter",
        "Forex": "Opérations de change",
        "Instruct this deal": "Demander cette transaction",
        "Deal Booked": "Transaction conclue",
        "Add Account": "Ajouter un compte",
        "Account Name": "Nom de compte",
        "Description": "Description",
        "ADD NEW ACCOUNT": "AJOUTER UN NOUVEAU COMPTE",
        "Rate has expired": "Le taux a expiré",
        "seconds": "secondes",
        "This rate expires in": "Ce taux expire en",
        "Business Account Application": "Demande de compte d’entreprise",
        "Loans": "Prêts",
        "Password": "Mot de passe",
        "Sign up": "S'inscrire",
        "Confirm Password": "Confirmer le mot de passe",
        "and": "et",
        "I agree with your": "je partage votre",
        "Privacy Policy": "Politique de confidentialité",
        "Terms of Service": "Conditions de service",
        "Should accept agreement": "Devrait accepter l’accord",
        "re-enter password": "Répétez le mot de passe",
        "The two passwords that you entered do not match!": "Les deux mots de passe que vous avez entrés ne correspondent pas!",
        "Phone number is invalid": "Numéro de téléphone est invalide",
        "Transfer To": "Transfert à",
        "Transfer From": "transfert de",
        "Transfer Money": "Transférer de l'argent",
        "Optional": "Optionnel",
        "Note": "Note",
        "International": "International",
        "Domestic": "Domestique",
        "Select transaction type": "Sélectionner le type de transaction",
        "Bank Account": "compte bancaire",
        "Prepaid Visa Card": "Carte Visa Prépayée",
        "Mobile Money (Momo) Account": "Compte Mobile Money (Momo)",
        "Other Javolin Account": "Autre compte Javolin",
        "Send Money": "Envoyer de L'argent",
        "Destination": "Destination",
        "Add New Beneficiary": "Ajouter un nouveau bénéficiaire",
        "Beneficiary Receives": "Bénéficiaire Reçoit",
        "Sending From": "Envoyer Depuis",
        "Sending info": "Envoi d’information",
        "Transaction Type": "Type de Transaction",
        "Send to": "Envoyer à",
        "Beneficiary details": "Détails du bénéficiaire",
        "Your deal has been booked. Your deal number is": "Votre contrat a été conclu. Votre numéro de contrat est",
        "book rate": "tarif des livres",
        "check rate": "vérifier le taux",
        "Select the default currency for your account": "Sélectionnez la devise par défaut pour votre compte",
        "Currency": "Unité monétaire",
        "currency": "unité monétaire",
        "Manage your wallet": "Grer votre portefeuille",
        "Add new role": " Ajouter un nouveau rôle",
        "Add Role": "Ajouter un rôle",
        "Set Transaction Limit": "Établir la limite de transaction",
        "Role Permissions": "Autorisations de rôle",
        "Role Name": "Nom de rôle",
        "Role List": "Liste des rôles",
        "User List": "liste d'utilisateurs",
        "Add user": "Ajouter un utilisateur",
        "Add new user": "Ajouter un nouvel utilisateur",
        "Full name": "Nom complet",
        "Full Name": "Nom complet",
        "delete": "supprimer",
        "edit": "modifier",
        "cancel": "annuler",
        "approve": "approuvez",
        "Selling Amount": "Montant de vente",
        "Transaction fee": "Frais d'opération",
        "Trans. Fee": "Frais d'opération",
        "Initial Trans. Amount": "Montant de la transaction initiale",
        "Source account": "Compte Source",
        "Bank name": "nom de la banque",
        "Beneficiary account": "Compte bénéficiaire",
        "User name": "Nom d'utilisateur",
        "Transaction made by": "transaction effectuée par",
        "Review Pending Transaction": "Examiner la transaction en attente",
        "decline": "déclin",
        "view": "vue",
        "Action": "Action",
        "Transaction ID": "Identifiant de transaction",
        "No data": "pas de données",
        "Active accounts": "Comptes d'actifs",
        "Wallet": "Portefeuille",
        "Buy": "Achetez",
        "Sell": "Vendez",
        "Amount": "Montant",
        "Foreign Exchange Spot Rate": "Taux de change au comptant",
        "Rate result appears here": "Le résultat du taux apparaît ici",
        "Add account": "Ajouter un compte",
        "Your Wallet": "Votre Portefeuille",
        "Transactions": "Les transactions",
        "Balance": "Solde du compte",
        "Completed": "Terminé",
        "Javolin Services": "Services de Javolin",
        "Let's secure your account": "Sécurisons l'accès à votre compte",
        "Business KYC": "Bien connaître son client",
        "Set your account pin": "Définir le code PIN de votre compte",
        "Secure your Account": "Sécuriser votre compte",
        "Overview in 60s": "Aperçu en 60 sec",
        "Home": "Page d'accueil",
        "Roles": "Rôles",
        "Role": "Rôles",
        "Users": "Utilisateurs",
        "User Management": "Gestion d'utilisateurs",
        "Pending Requests": "Demandes en attente",
        "Already have an account?": "Vous avez déjà un compte?",
        "Continue": "Continuer",
        "Physical Address": "Adresse Physique",
        "Email": "Email",
        "Enter business type": "Entrer le type d’entreprise",
        "Enter business name": "Entrer le nom de l’entreprise",
        "Type of business": "Type d'entreprise",
        "is required": "est nécessaire de fournir",
        "Name of Business": "Nom de l'entreprise",
        "Create an account and start trading with us": "Créer un compte et commencer à négocier avec nous",
        "Register": "S'inscrire",
        "French": "Français",
        "English": "Anglais",
        "Login": "Connexion",
        "Phone number is required": "Numéro de téléphone est requis",
        "Create account": "Créez un compte",
        "Don’t have an account?": "Vous n’avez pas de compte ?",
        "Please input your password!": "Veuillez entrer votre mot de passe!",
        "Password?": "Mot de passe",
        "Phone number": "Numéro de téléphone",
        "Phone Number": "Numéro de téléphone",
        "Forgot Password?": "Mot de passe oublié?",
        "Sign in to start trading with us": "Connectez-vous pour commencer à négocier avec nous",
        "Sign in": "S'inscrire",
        WELCOME_TO: "Bienvenue à",
        WELCOME_SUB: "Des services financiers fiables.",
        HOME: 'Accueil',
        DASHBOARD: 'Tableau de bord',
        APPLICATIONS: 'Soumissions',
        PROGRESS: 'Progress',
        MAPVIEW:'Map view',
        PRODUCTS: 'Produits',
        JAVOLIN_WALLET: 'Portefeuille Javolin',
        TOTAL_BALANCE: 'Solde total',
        MAKE_DEPOSIT: 'Faire un dépôt',
        GET_STARTED: 'Commencer',
        SAVINGS: 'Des économies',
        JAVOLIN_LOANS: 'Prêts de Javolin',
        JAVOLIN_LOANS_DESC: 'Faites une demande de prêt hypothécaire et d\'autres produits de prêt en quelques minutes',
        JAVOLIN_LOANS_ACTION: 'Demander un prêt maintenant',
        BUY_AIRTIME: 'Acheter du temps d\'antenne',
        BUY_AIRTIME_DESC: 'Envoyer du temps d\'antenne à tous les réseaux',
        BUY_AIRTIME_ACTION: 'Envoyer du temps',
        SEND_MONEY: 'Envoyer de l\'argent',
        NEW: 'nouvelle',
        ADDRESS: "Adresse physique",
        COMING_SOON: 'Bientôt disponible',
        FIRST_NAME: "Prénom",
        SURNAME: "Nom de famille",
        PHONE: "Numéro de téléphone",
        EMAIL: "E-mail",
        SECURE_CREDIT_CARD: 'Carte de crédit sécurisée',
        LINE_GRAPH_DESC: 'Graphique linéaire des volumes de transactions par type',
        RECENT_TRANSACTION: 'Transactions',
        REFERENCE: 'Référence',
        INVEST: "Invest - Crypto",
        AMOUNT: 'Montante',
        TRANSACTION_TYPE: 'Type de transaction',
        DATE: 'Date',
        EDIT_PROFILE: 'Editer le profil',
        LOGOUT: 'Se déconnecter',
        HI: 'Salut',
        VIEW_ALL: 'Voir tout',
        SERVICES: "Prestations de service",
        FUND_MY_WALLET: "Financer mon portefeuille",
        FUND_MY_WALLET_MSG: "Rechargez votre compte Javolin",
        SEND_MONEY_MSG: "Envoyez de l'argent à votre famille et à vos amis",
        TRANSFER_MONEY: "Transférer de l'argent",
        TRANSFER_MONEY_MSG: "Transférer de l'argent entre vos comptes",
        PAYMENTS: "PAIEMENTS",
        GOV_FEE: "Frais gouvernementaux",
        APPLY_NOW: "appliquer maintenant",
        LOAN: "PRÊTE",
        LOAN_DESC: "Prêts",
        CARD: "CARTE",
        CARD_DESC: "Carte de crédit/débit sécurisée",
        PORTFOLIO: "Portefeuille",
        WALLET: "Porte monnaie",
        PROFILE: "Profil",
        ACCOUNT_BALANCE: "Solde du compte",
        ACCOUNT_NUMBER: "Numéro de compte",
        WELCOME_MSG: "Bienvenue à Javolin, nous sommes heureux de vous voir ici. Apprenez à mieux nous connaître. Regardez cette vidéo de 60 secondes",
        VERIFY_IDENTITY: "Vérifiez Votre Identité",
        VERIFY_IDENTITY_MSG: "Cliquez ici pour configurer votre KYC afin de sécuriser votre compte",
        EXCHANGE_RATE: "TAUX D'ÉCHANGE",
        MORTGAGE: "Hypothèque",
        MORTGAGE_WELCOME_MSG: "Les hypothèques sont compliquées. Nous en faisons notre problème, pas le vôtre. Achetez une maison maintenant.",
        ADD_CARD: "Ajouter une nouvelle carte",
        MY_CARDS: "Mes cartes",
        STATUS: "Statut",
        GET_PREPAID: "Obtenez des cartes prépayées",
        GET_PREPAID_MSG: "Obtenez votre carte physique ou virtuelle et effectuez des transactions rapides et transparentes",
        HELLO_THERE: "Bonjour",
        FUNDING_SOURCES: "Sources de financement",
        ADD_NEW_FUNDING: "Ajouter une nouvelle source de financement",
        APPLICATION_STATUS: "État de la candidature",
        PREPAID: "Prépayé",
        PENDING: "En attente",
        VIEW: "Voir",
        PERSONAL_INFO: "Renseignements personnels",
        CHANGE_PASSWORD: "Changer le mot de passe",
        CHANGE_PIN: "Changer d'épingle",
        GET_HELP: "Obtenir de l'aide",
        CONTACT: "Contacter",
        QUESTION: "Question",
        ANSWER: "Répondre",
        PASS_CHANGE_MSG: "Vous devrez répondre à cette question pour réinitialiser votre mot de passe",
        CHANGE_PIN_MSG: "Vous devrez entrer votre code PIN actuel et un nouveau code PIN",
        CURRENT_PIN: "Broche actuelle",
        NEW_PIN: "Nouvelle épingle",
        SUBMIT: "Soumettre",
        CONTINUE: "Continuez",
        CONTACT_INPUT_PLACEHOLDER: "Dites-nous comment nous pouvons aider",
        UPDATE: "Mise à jour",
        CHANGE_PROFILE_PIC: "Modifier la photo de profil",
        NEW_PASSWORD: "nouveau mot de passe",
        OLD_PASSWORD: "Ancien mot de passe",
        SECURITY_QUESTIONS_INTRO: "Vous devrez répondre à cette question pour réinitialiser votre mot de passe",
        FOREIGN_EXCHANGE: "Change",
        FOREIGN_EXCHANGE_DESC: "Obtenez des services de change.",
        PAYMENT_SENDMONEY: "Paiements / Envoyer de l'argent"
    }
}