import ApplicationNavBarItem from "./ApplicationNavBarItem";
import {
  HomeNavIcon,
  PendingTransactionNavIcon,
  UserManagementNavIcon,
  WalletNavIcon,
} from "../JavIcons";
import { setCurrentRoute } from "../duck/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ApplicationMenuNavBarItem from "./Components/ApplicationMenuNavBarItem";
import { useEffect, useState } from "react";

export default function ApplicationNavigationBar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const current_route = useSelector((state) => state?.resources?.current_route);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (val) => {
    console.log(val);
    dispatch(setCurrentRoute(val));
    history.push(val);
    // props.setIsSidebarVisible(false);
  };

  const menu_items = [
    {
      menu: (
        <ApplicationNavBarItem
          icon={UserManagementNavIcon}
          title={"User"}
          isActive={current_route === "/user-management"}
          onClick={(e) => {
            handleMenuClick("/user-management");
          }}
        />
      ),
      id: 1,
    },
    {
      menu: (
        <ApplicationNavBarItem
          icon={UserManagementNavIcon}
          title={"Roles"}
          isActive={current_route === "/role-management"}
          onClick={(e) => {
            handleMenuClick("/role-management");
          }}
        />
      ),
      id: 2,
    },
  ];

  return (
    <div className="tw-flex tw-flex-col tw-font-satoshi tw-text-white tw-w-full md:tw-w-72">
      <span className="tw-font-satoshiMedium tw-pb-4 tw-px-6 tw-text-gray-300">
        General
      </span>

      <ApplicationNavBarItem
        icon={HomeNavIcon}
        title={"Dashboard"}
        isActive={current_route === "/"}
        onClick={(e) => {
          handleMenuClick("/");
        }}
      />

      <ApplicationNavBarItem
        icon={WalletNavIcon}
        title={"Wallet"}
        isActive={current_route === "/business/wallet"}
        onClick={(e) => {
          handleMenuClick("/business/wallet");
        }}
      />

      <ApplicationNavBarItem
        icon={PendingTransactionNavIcon}
        title={"Pending Transactions"}
        isActive={current_route === "/business/requests"}
        onClick={(e) => {
          handleMenuClick("/business/requests");
        }}
      />

      <ApplicationMenuNavBarItem
        icon={UserManagementNavIcon}
        title={"User Management"}
        items={menu_items}
      />
    </div>
  );
}
